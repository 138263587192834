<template>
  <div>
    <Card padding="p-5 py-4">
      <Headline class="text-center mb-3">Login</Headline>
      <form role="form" class="text-start">
        <div class="mb-3">
          <Input v-model="email" type="email" placeholder="Email" />
        </div>
        <div class="mb-2">
          <Input v-model="password" type="password" placeholder="Passwort" />
        </div>
        <div class="mb-3 f-small">
          <NuxtLink to="/password-reset">Passwort vergessen?</NuxtLink>
        </div>
        <Button @click.prevent="login" type="submit" class="w-100">
          <Icon icon="fa-right-to-bracket" /> Login
        </Button>
      </form>
    </Card>
    <Modal ref="authModal" size="small" title="Bestätigungscode">
      <div class="row">
        <div class="col-12">
          Es wurde ein Bestätigungscode an deine Email Adresse gesendet.
        </div>
        <div class="col-12 mt-3">
          <div class="d-flex gap-2">
            <VOtpInput
              @on-complete="auth"
              v-model:value="emailToken"
              input-classes="form-control"
              separator=""
              input-type="letter-numeric"
              :num-inputs="6"
              :placeholder="['*', '*', '*', '*', '*', '*']"
              :should-auto-focus="true"
              :should-focus-order="true"
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button html-type="primary"><Icon icon="fa-check" /> Bestätigen</Button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script setup>
import VOtpInput from "vue3-otp-input";

definePageMeta({ layout: "variete", title: "Login" });

const authModal = ref({});
const emailToken = ref("");
const { signIn } = useAuth();
const email = ref("");
const password = ref("");
const { toast, runtimeConfig } = useNuxtApp();

async function login() {
  try {
    await signIn({ email: email.value, password: password.value }, { callbackUrl: "/", external: true });
  } catch (e) {
    if (e.response) toast.showError("Login", e.response?._data.info);
    else toast.showError("Login", e);
  }
}

// async function auth() {
//   // if (emailToken.value && loginToken.value) {
//   globalStore.isLoading = true;
//   const data = await $fetch(`${runtimeConfig.public.apiURL}/admins/auth-mail`, {
//     method: "POST",
//     body: { login_token: loginToken.value, email_token: emailToken.value },
//   });
//
//   if (data.success) {
//     const adminToken = useCookie("admin_token");
//     const client = useCookie("client");
//     const shop = useCookie("shop");
//
//     adminToken.value = data.admin.access_token;
//     if (data.admin.client_id) client.value = data.admin.client_id;
//     if (data.admin.shop_id) shop.value = data.admin.shop_id;
//
//     location.href = "/";
//   } else toast.showError("Login", data.info);
//
//   globalStore.isLoading = false;
//   // }
// }
</script>
